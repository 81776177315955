
@tailwind base;
@tailwind components;
@tailwind utilities;


/* Font Fira Code*/
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Fira Code', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #010C15;
  color: white;
}
::-webkit-scrollbar {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.nav-links {
  @apply py-[17px] border-b border-[#1E2D3D] px-[18px] font-normal;
}
.footer-links {
  @apply py-[10px] border-b border-[#1E2D3D] px-[18px] font-normal;
}

.rounded-Border{
  @apply border border-[#1E2D3D] ;
}


.transition{
  transition: 1s ease-in-out;
}

.hidenScroll::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
  height: 0px;
}



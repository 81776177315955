.home-content::before {
  content: "";
  position: absolute;
  /* background-image: url('../../Assets/svg/bg-blur.svg'); */
  background: linear-gradient(92deg, #43d9ad9c, #4d5bce4d);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.image-shadow {
  box-shadow: 0px -15px 100px 5px #43d9ad;
}

